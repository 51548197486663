<template>
	<div>
		<div class="faq">
			<div class="app-container">
				<h1 class="heading-2 green--text">{{$t('routes.faq')}}</h1>

				<div v-for="(item, index) in items" :key='index'>
					<div>
						<v-accordion>
							<template v-slot:title>{{ item.question }}</template>

							<template v-slot:content>
								<div class="app-accordion__content-block" v-html="item.text"></div>
							</template>
						</v-accordion>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getFaqHelpCenter } from '@/services/request';


	export default {
		data: () => ({
            items:[],
			Accordion: {
				count: 0,
				active: null
			}
		}),
		provide() {
			return { Accordion: this.Accordion }
		},
        created() {
            this.getFaqHelpCenter();
        },
        methods:{
            getFaqHelpCenter(){
                getFaqHelpCenter().then(r => {
                    this.items = r.data;
                })
            }
        }
    }
</script>

<style lang='scss'>
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.faq {
        min-height: calc(100vh - 320px);
		background-color: $cyan-light;
		padding: 40px 0 60px;
		@include minw( $grid-breakpoints-md ) {
			padding: 100px * .8 0;
		}
		@include minw( $grid-breakpoints-lg ) {
			padding: 40px 0;
		}
		.heading-2 {
			margin-bottom: 30px;
			@include minw( $grid-breakpoints-sm ) {
				margin-bottom: 40px;
			}
		}
	}
</style>
